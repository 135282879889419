import "../../directives/accordion.js";
import { ApiPollingService } from "booking_app/services/api-polling.service";

export class FlightsTermsModalCtrl {
  static $inject = [
    "$scope",
    "$rootScope",
    "$modalInstance",
    "ApiPollingService",
    "bookingKey",
    "rules",
    "customTitle",
  ];

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $modalInstance: any,
    private apiPollingService: ApiPollingService,
    private bookingKey: any,
    private rules: any,
    private customTitle: string,
  ) {
    this.$scope.termsLoading = true;

    this.$scope.closeTermsModal = () => {
      this.$modalInstance.close("close");
    };

    if (rules) {
      this.displayFareRules(rules);
    } else if (bookingKey) {
      this.getFareRules(bookingKey);
    }

    if (customTitle) {
      this.$scope.customTitle = customTitle;
    }
  }

  buildUrl(path): any {
    return `${path}?landing_page=${this.$rootScope.landingPage.url || ""}`;
  }

  getFareRules(bookingKey): any {
    this.apiPollingService.poll(this.buildUrl(`flights/${bookingKey}/terms`), {}).then((data) => {
       this.displayFareRules(data.rules);
    }, (errors) => {
      console.log("Flights Terms Error:", errors[0]);
    });
  }

  displayFareRules(rules): any {
    this.$scope.termsData = rules;
    this.$scope.termsLoading = false;
  }
}

angular.module("BookingApp").controller("FlightsTermsModalCtrl", FlightsTermsModalCtrl);
